import React from "react"
import loadable from "@loadable/component"
const SimpleInBrand = loadable(() => import("./StatisticsSimpleInBrand"))
const SimpleInCard = loadable(() => import("./StatisticsSimpleInCard"))

export const Statistics = ({statistics:{statisticsCards,type,subtitle,title},theme,index}) => {
 const statisticsValues=statisticsCards?.statisticsCPT?.statisticsValues
  const StatisticsBlock = { title, subtitle, statisticsValues };
  switch (type) {
    case "Simple on brand":
      return <SimpleInBrand {...StatisticsBlock} theme={theme} index={index}/>
    case "Simple in card":
      return <SimpleInCard {...StatisticsBlock} theme={theme} index={index}/>

    default:
     return <div></div>
  }

}

export default Statistics
